import Api from '@/services/Index';


const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/students/high-honor-report', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/students/high-honor-report'+id);
}
const downloadAsExcel = async (config) =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/students/high-honor-report/export-excel',{...config,responseType:'arraybuffer'})

}

export default {
    getAll, get,downloadAsExcel
}
